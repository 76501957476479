import React, { createContext, useContext } from 'react';

/*
 	This context provides an image component to be used throughout the application.
	By default, it provides an <img /> element, but you can provide a custom component,
	such as Next.js's <Image />.
*/

type ImageOptimizationComponentProps = Record<string, unknown> & {
	src: string;
	alt: string;
	width: number;
	height: number;
};

export type ImageOptimizationComponent = React.FC<ImageOptimizationComponentProps>;

interface ImageOptimizationContextProviderProps {
	children?: React.ReactNode;
	imageOptimization: ImageOptimizationComponent;
}

const ImageOptimizationContext = createContext(null);

export const ImageOptimizationContextProvider: React.FC<ImageOptimizationContextProviderProps> = (props) => {
	const { children, imageOptimization } = props;

	let returnedImageOptimization: ImageOptimizationComponent | 'img' = imageOptimization;

	if (typeof returnedImageOptimization !== 'function') {
		console.warn('ImageOptimizationContextProvider: No valid imageOptimization provided. Defaulting to <img />.');

		returnedImageOptimization = 'img';
	}

	return <ImageOptimizationContext.Provider value={returnedImageOptimization}>{children}</ImageOptimizationContext.Provider>;
};

/**
 * @function useImageOptimizationContext
 * @returns {ImageOptimizationComponent} The provided image component or a default <img> element.
 */
export const useImageOptimizationContext = (): ImageOptimizationComponent | 'img' => {
	return useContext(ImageOptimizationContext) ?? 'img';
};
