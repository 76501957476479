import config from 'utilities/imageSizes';

interface GetImageUrlProps {
	url: string;
	crop?: boolean;
	focalPoint?: {
		left: number;
		top: number;
	};
	width?: number;
	height?: number;
	format?: 'webp';
}

export const GetImageUrl = ({ url, focalPoint, width, height = 0, format }: GetImageUrlProps): string => {
	if (!url) return '';
	if (url?.endsWith('.svg')) return url;
	let imageUrl = `${url}?width=${width}`;

	if (height > 0) {
		imageUrl = `${imageUrl}&height=${height}&mode=crop`;
	}
	if (height > 0 && focalPoint) {
		imageUrl = `${imageUrl}&rxy=${focalPoint.left.toString().replace(',', '.')},${focalPoint.top.toString().replace(',', '.')}`;
	}

	if (format) imageUrl = `${imageUrl}&format=webp`;
	return imageUrl;
};

export const GetImageUrl2 = ({ url, crop, focalPoint, width, height }: GetImageUrlProps): string => {
	if (!url) return '';
	if (url?.endsWith('.svg')) return url;

	let imageUrl = `${url}`;

	// Next handles sizes in srcset automaticly, but cannot crop the image
	if (crop) {
		imageUrl = `${imageUrl}?width=${width}&height=${height}&mode=crop`;
	}

	if (crop && focalPoint) {
		imageUrl = `${imageUrl}&rxy=${focalPoint.left.toString().replace(',', '.')},${focalPoint.top.toString().replace(',', '.')}`;
	}

	return imageUrl;
};

export const GetSrcSetString = ({
	url,
	focalPoint,
	format,
	aspectRatio,
	height = 0,
	overwriteWidth,
}: {
	url: string;
	focalPoint: { left: number; top: number };
	format?: 'webp';
	aspectRatio?: number;
	height?: number;
	overwriteWidth?: number;
}): string => {
	const { imageSizes } = config.images;

	let string = '';

	if (overwriteWidth !== null && overwriteWidth !== undefined) {
		return GetImageUrl({
			url,
			focalPoint,
			width: overwriteWidth,
			height: height,
			format,
		});
	}

	imageSizes.map((width) => {
		let convertedHeight = height;
		if (aspectRatio) {
			convertedHeight = aspectRatio * width;
		}

		return (string += `${GetImageUrl({
			url,
			focalPoint,
			width,
			height: convertedHeight,
			format,
		})} ${width}w, `);
	});

	return string.substring(0, string.length - 2);
};

export const GetSrcSetString2 = ({
	url,
	focalPoint,
	format,
	aspectRatio,
}: {
	url: string;
	focalPoint: { top: number; left: number };
	format?: 'webp';
	aspectRatio?: number;	
	height?: number;
	width?: number;
}): string => {
	const { imageSizes } = config.images;

	let string = '';

	imageSizes.map((width) => {
		let height = 0;

		if (aspectRatio) {
			height = aspectRatio * width;
		}

		return (string += `${GetImageUrl({
			url,
			focalPoint,
			height,
			width,
			format,
		})} ${width}w, `);
	});

	return string.substring(0, string.length - 2);
};
