import classNames from 'classnames';
import { useImageOptimizationContext } from "contexts/ImageOptimizationContext";
import { useEffect, useRef, useState } from 'react';
import { GetImageUrl2 } from 'utilities/getImageUrl';
import { inView } from 'utilities/inView';
import styles from './Picture2.module.scss';

export interface Picture2Props {
	altText: string;
	aspectRatio?: number;
	border?: boolean;
	cropImage?: boolean;
	fetchpriority?: 'high' | 'low' | 'auto';
	focalPoint?: { left: number; top: number };
	height?: number;
	imageClassName?: string;
	imageCropping?: 'cover' | 'contain';
	isCover?: boolean;
	isFullHeight?: boolean;
	isFullWidth?: boolean;
	lazyload?: boolean;
	padding?: 'none' | 'small' | 'large';
	pictureClassName?: string;
	pictureHeight?: number;
	sizes?: string;
	url: string;
	width?: number;
}

export const Picture2 = ({
	altText,
	aspectRatio,
	border,
	cropImage,
	fetchpriority,
	focalPoint,
	height = 0,
	imageClassName,
	imageCropping = 'cover',
	isCover,
	isFullHeight,
	isFullWidth,
	lazyload = true,
	padding = 'none',
	pictureClassName,
	pictureHeight,
	sizes = '100vw',
	url = '',
	width = 0,
}: Picture2Props) => {
	const ImageOptimization = useImageOptimizationContext();
	const pictureRef = useRef(null);

	const preLoadedImageUrl = GetImageUrl2({ url, crop: cropImage, focalPoint, width: 2, height: 2 });
	const [src, setSrc] = useState(preLoadedImageUrl);

	const focalPointToCSS = focalPoint ? focalPoint.left * 100 + '% ' + focalPoint.top * 100 + '%' : '50% 50%';

	const reveal = () => {
		const srcString = GetImageUrl2({ url });
		setSrc(srcString);
	};

	useEffect(() => {
		if (lazyload) inView({ elm: pictureRef.current, callback: reveal });
		if (!lazyload) reveal();
	});

	return (
		<picture
			ref={pictureRef}
			className={classNames(
				styles.Picture,
				'Picture__override',
				{ 
					[styles.Picture___fullHeight]: isFullHeight,
					[styles.Picture___fullWidth]: isFullWidth,
				},
				styles[`Picture___padding${padding}`],
				pictureClassName !== undefined ? pictureClassName : '',
			)}
			style={{
				height: pictureHeight !== undefined ? `${pictureHeight}px` : null,
			}}
		>
			<ImageOptimization
				className={classNames(
					styles.PictureImage,
					'PictureImage__override',
					{ 
						[styles.PictureImage___border]: border,
						[styles.PictureImage___cover]: isCover,
					},
					imageClassName,
				)}
				src={src}
				width={width}
				height={height}
				alt={altText ?? ''}
				sizes={sizes}
				style={{ objectPosition: imageCropping === 'contain' ? 'initial' : focalPointToCSS, aspectRatio: aspectRatio !== undefined && aspectRatio !== 0 ? `1/${aspectRatio}` : 'auto' }}
				fetchpriority={fetchpriority || 'auto'}
				loading={lazyload === false ? 'eager' : 'lazy'}
			/>
		</picture>
	);
};
